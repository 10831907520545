//  σελίδα wheel4.js
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

function Wheel4() {
  const location = useLocation();
  const { userId } = useParams();

  return (
    <div>
      <h1>Wheel 4 Page</h1>
      <p>User ID: {userId}</p>
    </div>
  );
}

export default Wheel4;