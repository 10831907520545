import React from 'react';
import { Link } from 'react-router-dom';
import './luckyseat.css';

function LuckyseatPage() {
  return (
    <div className="luckyseat-container">
    {/* Code to show only if we are on app.js */}
    
      <div id="luckyseat-button-container">
        <button className="luckyseatbutton">
          <Link to = "/data">Πίσω</Link>
        </button>
      </div>
  
  </div>
  );
}

export default LuckyseatPage;