import image1 from "./dorotroxos -02.png"
import image2 from "./dorotroxos -04.png"
import image3 from "./dorotroxos -05.png"
import image4 from "./dorotroxos -06.png"
import image5 from  "./dorotroxos -07.png"
import image6 from "./dorotroxos -08.png"
import background from "./background.png";

const IMAGES = {
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    background
}
export default IMAGES