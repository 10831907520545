// store/store.js

import { createStore, combineReducers } from "redux";
import giftCountsReducer from "./giftCountsReducer";
import giftCountsReducer2 from "./giftCountsReducer2"; 
import giftCountsReducer3 from "./giftCountsReducer3"; 

const rootReducer = combineReducers({
  giftCounts: giftCountsReducer,
  giftCounts2: giftCountsReducer2,
  giftCounts3: giftCountsReducer3,
  
});

const store = createStore(rootReducer);

export default store;
