import React, { useState, useEffect } from 'react';
import { firestore } from '../../services/firebase.service';
import { useNavigate } from 'react-router-dom';
import './data.css';

function Data() {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    city: '',
    
  });

  const [errors, setErrors] = useState({
    phone: '',
    email: '',
  });



  const navigate = useNavigate();

  const citiesList = ["Θεσσαλονίκη"];

  const [tabletId, setTabletId] = useState('');

  const [gifts, setGifts] = useState([]); // Λίστα με τα δώρα από τη βάση δεδομένων

  useEffect(() => {
    const storedTabletId = localStorage.getItem('tabletId');
    if (storedTabletId) {
      setTabletId(storedTabletId);
    } else {
      const newTabletId = generateTabletId();
      localStorage.setItem('tabletId', newTabletId);
      setTabletId(newTabletId);
    }

    // δώρα από τη βάση δεδομένων Firestore
    fetchGiftsFromFirestore(formData.city);
  }, [formData.city]);

  const fetchGiftsFromFirestore = async (selectedCity) => {
    try {
      let giftsCollectionName = 'gifts'; 

      if (selectedCity === 'Θεσσαλονίκη') {
        giftsCollectionName = 'gifts';
      } else if (selectedCity === 'Ιωάννινα') {
        giftsCollectionName = 'gifts2';
      } else if (selectedCity === 'Σέρρες') {
        giftsCollectionName = 'gifts3';
      }

      const giftsRef = firestore.collection(giftsCollectionName);
      const snapshot = await giftsRef.get();
      const giftData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setGifts(giftData);
      console.log('we are in');
    } catch (error) {
      console.error('Σφάλμα κατά την ανάκτηση των δώρων από τη Firestore:', error);
    }
  };

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const userRef = firestore.collection('data').doc();
      const userId = userRef.id;
  
      const selectedCity = formData.city;
  
      let nextPage = '/wheel1'; // Προεπιλογή, αν δεν υπάρχει αντιστοίχιση
  
      if (selectedCity === 'Θεσσαλονίκη') {
        nextPage = '/wheel1';
      } else if (selectedCity === 'Ιωάννινα') {
        nextPage = '/wheel2';
      } else if (selectedCity === 'Σέρρες') {
        nextPage = '/wheel3';
      }
  
      // Προσθέστε το userId στο URL όταν πλοηγείστε
      navigate(`${nextPage}?userId=${userId}`);
  
      // Στη συνέχεια, αποθηκεύετε τα δεδομένα στη βάση Firestore
      userRef
        .set({
          ...formData,
          userId: userId,
          tabletId: tabletId, // tabletId στα δεδομένα
          time: new Date().toLocaleString(),
        })
        .then(() => {
          console.log('Επιτυχής αποθήκευση στη Firestore');
        })
        .catch((error) => {
          console.error('Σφάλμα κατά την αποθήκευση στη Firestore:', error);
        });
    } else {
      console.log('Συμπληρώστε όλα τα υποχρεωτικά πεδία');
    }
  };
  
  

  const validateForm = () => {
    const { city, name, surname, phone, email, newsletter } = formData;
    return city && name && surname && validatePhone(phone) && validateEmail(email) ;
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  return (
    <div className="data-container">
      <div className="city-container">
        <select name="city" value={formData.city} onChange={handleChange} required>
          <option value="">Επιλέξτε πόλη</option>
          {citiesList.map((city) => (
            <option key={city} value={city}>
              {city}
            </option>
          ))}
        </select>
      </div>

      <div className="name-container">
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Όνομα:"
        />
      </div>

      <div className="surname-container">
        <input
          type="text"
          name="surname"
          value={formData.surname}
          onChange={handleChange}
          placeholder="Επίθετο:"
        />
      </div>

      <div className="phone-container">
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Τηλέφωνο:"
        />
        {errors.phone && <span className="error">{errors.phone}</span>}
      </div>

      <div className="email-container">
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email:"
        />
        {errors.enail && <span className="error">{errors.email}</span>}
      </div>

     
          

      <div className="submitbutton-container">
        <button className="submitbutton" onClick={handleSubmit} disabled={!validateForm()}>
          Υποβολή
        </button>
      </div>
    </div>
  );
}

function generateTabletId() {
  //τυχαιος τροπος για την δημιουργια του tabletid
  return 'tablet-' + Math.random().toString(36).substring(2, 15);
}

export default Data;
