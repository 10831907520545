import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { firebase } from '../../services/firebase.service';
import * as XLSX from 'xlsx';
import './dashboardhome.css';

const DashboardHome = () => {
  const [data, loading, error] = useCollectionData(
    firebase.firestore()
      .collection('data')
      .orderBy('time', 'desc') // Ταξινόμηση με βάση το timestamp σε φθίνουσα σειρά
  );
  
  const [gkelakiaData, gkelakiaLoading, gkelakiaError] = useCollectionData(firebase.firestore().collection('gkelakia'));
  const [physicalData, physicalLoading, physicalError] = useCollectionData(firebase.firestore().collection('physical'));
  const [filterField1, setFilterField1] = useState('city');
  const [filterValue1, setFilterValue1] = useState('');
  const [filterField2, setFilterField2] = useState('city');
  const [filterValue2, setFilterValue2] = useState('');
  const [filterField3, setFilterField3] = useState('city');
  const [filterValue3, setFilterValue3] = useState('');
  const navigate = useNavigate();

  const handleFilterField1Change = (event) => {
    setFilterField1(event.target.value);
  };

  const handleFilterValue1Change = (event) => {
    setFilterValue1(event.target.value);
  };


  const handleFilterField2Change = (event) => {
    setFilterField2(event.target.value);
  };

  const handleFilterValue2Change = (event) => {
    setFilterValue2(event.target.value);
  };

  const handleFilterField3Change = (event) => {
    setFilterField3(event.target.value);
  };

  const handleFilterValue3Change = (event) => {
    setFilterValue3(event.target.value);
  };
 
 
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Εφαρμογή του φίλτρου
  const filteredData = data.filter(item => {
    const fieldValue1 = item[filterField1] ? item[filterField1].toLowerCase() : '';
    const fieldValue2 = item[filterField2] ? item[filterField2].toLowerCase() : '';
    const fieldValue3 = item[filterField3] ? item[filterField3].toLowerCase() : '';
    return fieldValue1.includes(filterValue1.toLowerCase()) && fieldValue2.includes(filterValue2.toLowerCase()) && fieldValue3.includes(filterValue3.toLowerCase());
  });


  const handleDownload = () => {
    // Προτροπή για τον κωδικό
    const password = window.prompt('Enter password:');
    
    // Έλεγχος αν ο κωδικός είναι σωστός
    if (password !== '1234') {
      alert('Invalid password. Download canceled.');
      return;
    }

    // Δημιουργία του αρχείου Excel
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    

    // Καθορισμός του ονόματος του αρχείου με βάση την ημερομηνία
    const date = new Date();
    const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    const fileName = `data_${dateString}.xlsx`;

    // Κατεβάστε το αρχείο
    XLSX.writeFile(wb, fileName);
  };


  const handleExport = (exportData) => {
    // Προτροπή για τον κωδικό
    const password = window.prompt('Enter password:');
    
    // Έλεγχος αν ο κωδικός είναι σωστός
    if (password !== '1234') {
      alert('Invalid password. Download canceled.');
      return;
    }

    // Δημιουργία του αρχείου Excel
    const ws = XLSX.utils.json_to_sheet(exportData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');

    // Καθορισμός του ονόματος του αρχείου με βάση την ημερομηνία
    const date = new Date();
    const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    const fileName = `data_${dateString}.xlsx`;

    // Κατεβάστε το αρχείο
    XLSX.writeFile(wb, fileName);
  };

  const handleExportGkelakia = () => {
    handleExport(gkelakiaData);
  };

  const handleExportPhysical = () => {
    handleExport(physicalData);
  };


  const total = filteredData.length;

  return (
    <div className="dashboard-container">
      <h1>Dashboard For Novibet Wheel of Spin</h1>
      <div>
      <label>
        Επιλέξτε πεδίο πρώτου φίλτρου:
        <select value={filterField1} onChange={handleFilterField1Change}>
          <option value="city">City</option>
          <option value="tabletId">Tablet ID</option>
          <option value="userId">User ID</option>
          <option value="name">First Name</option>
          <option value="surname">Last Name</option>
          <option value="email">Email</option>
          <option value="phone">Phone no</option>
          <option value="gift">Gift</option>
          <option value="time">Time</option>
          {/* Προσθέστε περισσότερα πεδία ανάλογα με την δομή της συλλογής σας */}
        </select>
      </label>
      <input
        type="text"
        placeholder="Πρώτο Φίλτρο"
        value={filterValue1}
        onChange={handleFilterValue1Change}
      />
      </div>

      <div style={{ marginTop: '10px' }}>
      <label>
        Επιλέξτε πεδίο δεύτερου φίλτρου:
        <select value={filterField2} onChange={handleFilterField2Change}>
          <option value="city">City</option>
          <option value="tabletId">Tablet ID</option>
          <option value="userId">User ID</option>
          <option value="name">First Name</option>
          <option value="surname">Last Name</option>
          <option value="email">Email</option>
          <option value="phone">Phone no</option>
          <option value="gift">Gift</option>
          <option value="time">Time</option>
          {/* Προσθέστε περισσότερα πεδία ανάλογα με την δομή της συλλογής σας */}
        </select>
      </label>
      <input
        type="text"
        placeholder="Δεύτερο Φίλτρο"
        value={filterValue2}
        onChange={handleFilterValue2Change}
      />
      
      </div>
      <div style={{ marginTop: '10px' }}>
      <label>
        Επιλέξτε πεδίο τρίτου φίλτρου:
        <select value={filterField3} onChange={handleFilterField3Change}>
          <option value="city">City</option>
          <option value="tabletId">Tablet ID</option>
          <option value="userId">User ID</option>
          <option value="name">First Name</option>
          <option value="surname">Last Name</option>
          <option value="email">Email</option>
          <option value="phone">Phone no</option>
          <option value="gift">Gift</option>
          <option value="time">Time</option>
          {/* Προσθέστε περισσότερα πεδία ανάλογα με την δομή της συλλογής σας */}
        </select>
      </label>
      <input
        type="text"
        placeholder="Τρίτο Φίλτρο"
        value={filterValue3}
        onChange={handleFilterValue3Change}
      />
      
      </div>
      <div style={{ marginTop: '10px' }}>
      <button onClick={handleDownload}>Download Excel</button>
      </div>
      <div style={{ marginTop: '10px' }}>
        <button onClick={handleExportGkelakia}>Export Gkelakia Excel</button>
      </div>

      <div style={{ marginTop: '10px' }}>
        <button onClick={handleExportPhysical}>Export Physical Excel</button>
      </div>
      <div style={{ fontWeight: 'bold', fontSize: '25px', color: '#6DF1FF' , marginTop:'10px'}}>
      Total: {total}
    </div>
      <table className="dashboard-table">
        <thead>
          <tr>
            <th>City</th>
            <th>Tablet ID</th>
            <th>User Id</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Phone no</th>
            <th>Gift</th>
            <th>Time</th>
            {/* Προσθέστε περισσότερα θέματα ανάλογα με την δομή της συλλογής σας */}
          </tr>
        </thead>
        
        <tbody>
    {filteredData
      .sort((a, b) => {
        // Μετατροπή των τιμών του πεδίου "time" σε αντικείμενα ημερομηνίας και ώρας
        const dateA = new Date(a.time);
        const dateB = new Date(b.time);

        // Σύγκριση των αντικειμένων ημερομηνίας και ώρας για την ταξινόμηση
        return dateB - dateA; // Ταξινόμηση με βάση την ημερομηνία και την ώρα σε φθίνουσα σειρά
      })
      .map((item) => (
        <tr key={item.id}>
          <td>{item.city}</td>
          <td>{item.tabletId}</td>
          <td>{item.userId}</td>
          <td>{item.name}</td>
          <td>{item.surname}</td>
          <td>{item.email}</td>
          <td>{item.phone}</td>
          <td>{item.gift}</td>
          <td>{item.time}</td>
          {/* Προσθέστε περισσότερα πεδία ανάλογα με τη δομή της συλλογής σας */}
        </tr>
      ))}
  </tbody>
        
      </table>
    </div>
  );
};

export default DashboardHome;
