import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


const firebaseConfig = {
  apiKey: 'AIzaSyDaa1ad1-ZO9OQddmo1zVV03Ty-pVZjFoI',
  authDomain: 'novibet-wheel-of-fortune.firebaseapp.com',
  projectId: 'novibet-wheel-of-fortune',
  storageBucket: 'novibet-wheel-of-fortune.appspot.com',
  messagingSenderId: '774371911542',
  appId: '1:774371911542:web:f1c18f4cfcd969c0adfc57',
  measurementId: "G-LDNQNXHYS2"
};


firebase.initializeApp(firebaseConfig);


const firestore = firebase.firestore();

export { firebase, firestore };
