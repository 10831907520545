
import React, { useState, useEffect } from "react";
import { connect } from 'react-redux'; // Εισαγωγή του connect
import Wheel2Component from "./wheel2.component";
import "./wheel2.css";
import { useNavigate, useLocation } from 'react-router-dom';
import { firestore } from '../../services/firebase.service';
import IMAGES from "../../assets";

function Wheel2(props) {
  const [show, setShow] = useState(false);
  const { giftCounts } = props; // Λαμβάνουμε το giftCounts από το Redux store

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');

  useEffect(() => {
    // Έλεγξε αν το giftCounts είναι κενό πριν ανακτήσεις δεδομένα από τη βάση Firestore
    if (Object.keys(giftCounts).length === 0) {
      const fetchGifts = async () => {
        try {
          const giftsRef = firestore.collection("gifts2");
          const snapshot = await giftsRef.get();
          const giftData = {};
          snapshot.forEach((doc) => {
            const name = doc.id;
            const data = doc.data();
            giftData[name] = data.quantity;
          });

          props.updateGiftCounts(giftData);

          console.log('Πήραμε αριθμούς από τη βάση:', giftData);

          setShow(true);
        } catch (error) {
          console.error("Σφάλμα κατά την ανάκτηση των δώρων από τη βάση δεδομένων:", error);
        }
      };

      fetchGifts();
    } else {
      // Αν το giftCounts δεν είναι κενό, εμφάνισε τον τροχό
      setShow(true);
    }
  }, []);

  const checkLuckySeatDrawing = () => {
    const now = new Date();
    const dayOfWeek = now.getDay(); // 0 για Κυριακή, 1 για Δευτέρα, κ.λπ.
    const hours = now.getHours();
    
    //  αν είναι Σάββατο και είναι μεταξύ 18:00 και 18:30 ή 20:00 και 20:30
    if (dayOfWeek === 4 && ((hours >= 18.4 && hours < 18.5) || (hours >= 20 && hours < 20.5))) {
      
      if (giftCounts["Lucky Seat"] > 0) {
        const updatedCounts = { ...giftCounts };
        updatedCounts["Lucky Seat"]--;
        
        // Χρησιμοποιήστε το Redux για να ενημερώσετε τα giftCounts
        props.updateGiftCounts(updatedCounts);

        //  συνάρτηση για την αποθήκευση του δώρου στη βάση δεδομένων
        saveGiftToDatabase(userId, "Lucky Seat");

        navigate('/luckyseat');
      } else {
        navigate('/sorry');
      }
    }
  };

  const giftToRouteMap = {
    "VIP Tickets": '/vip',
    "T-Shirt": '/jersey',
    "100 Free Spins": '/spins',
    "Regular Tickets": '/regular',
    "€50 free bet": '/bet',
    "Sorry": '/sorry',
    "Lucky Seat": '/luckyseat',
    "Jockey": '/jockey'
  };


  const onFinished = async (winner, giftCounts) => {
    console.log('winner:', winner);
  
    if (giftCounts[winner] > 0) {
      console.log('Κέρδισες:', winner);
      // Το δώρο υπάρχει στη βάση, πηγαίνουμε στη σελίδα του δώρου
      navigate(giftToRouteMap[winner]);
      saveGiftToDatabase(userId, winner);
  
      // Ενημερώνουμε την ποσότητα του δώρου στη βάση
      updateGiftQuantity(winner);

      const updatedCounts = { ...giftCounts };
      updatedCounts[winner]--;

      // redux for giftcounts
      props.updateGiftCounts(updatedCounts);
  
    } else {
      // Το δώρο δεν υπάρχει στη βάση, βρίσκουμε το επόμενο δώρο στη λίστα των segments
      const nextGiftIndex = segments.indexOf(winner) + 1;
      const nextGift = segments[nextGiftIndex];
  
      if (giftCounts[nextGift] > 0) {
        console.log('1o segment', nextGift);
        // Πηγαίνουμε στη σελίδα του επόμενου δώρου
        navigate(giftToRouteMap[nextGift]);
        saveGiftToDatabase(userId, nextGift);
        updateGiftQuantity(nextGift);
        const updatedCounts = { ...giftCounts };
      updatedCounts[nextGift]--;

      // redux for giftcounts
      props.updateGiftCounts(updatedCounts);
      } else {
        const nextGiftIndex = segments.indexOf(winner) +2;
        const nextGift = segments[nextGiftIndex];
        if(giftCounts[nextGift]> 0) {
          console.log('2o segment', nextGift);
          //Πηγαίνουμε στη σελίδα του επόμενου δώρου
          navigate(giftToRouteMap[nextGift]);
          saveGiftToDatabase(userId,nextGift);
          updateGiftQuantity(nextGift);
          const updatedCounts = { ...giftCounts };
          updatedCounts[nextGift]--;

          //redux for giftcounts
          props.updateGiftCounts(updatedCounts);
        }else{
          console.log('tipota');
        // Δεν υπάρχει επόμενο δώρο, πηγαίνουμε στη σελίδα 'Sorry'
        navigate('/sorry');
        saveGiftToDatabase(userId, "Sorry");
      }
    }
  }
  };

  const saveGiftToDatabase = (userId, gift) => {
    const userRef = firestore.collection("data").doc(userId);
    userRef
      .update({
        gift: gift,
      })
      .then(() => {
        console.log("Επιτυχής αποθήκευση του δώρου στη βάση δεδομένων.");
      })
      .catch((error) => {
        console.error("Σφάλμα κατά την αποθήκευση του δώρου:", error);
      });
  };

  const updateGiftQuantity = async (giftName) => {
    try {
      const giftsRef = firestore.collection("gifts2");
      const giftDoc = await giftsRef.doc(giftName).get();

      if (giftDoc.exists) {
        const currentQuantity = giftDoc.data().quantity;
        if (currentQuantity > 0) {
          await giftsRef.doc(giftName).update({
            quantity: currentQuantity - 1,
          });
          console.log(`Επιτυχής ενημέρωση της ποσότητας για το δώρο "${giftName}".`);
        } else {
          console.warn(`Το δώρο "${giftName}" δεν είναι διαθέσιμο.`);
        }
      } else {
        console.warn(`Το δώρο "${giftName}" δεν βρέθηκε στη συλλογή "gifts".`);
      }
    } catch (error) {
      console.error(`Σφάλμα κατά την ενημέρωση της ποσότητας για το δώρο "${giftName}":`, error);
    }
  };

  const segments = [
    "VIP Tickets",
    "Jockey",
    "100 Free Spins",
    "Regular Tickets",
    "€50 free bet",
    "Sorry",
    "Lucky Seat",
    "T-Shirt"
    
  ];

  const weelColors = () => {
    let arr = [];
    let colors = ["#6DF1FF", "#05B7BA"];
    segments.forEach((el) => {
      let color = colors.shift();
      arr.push(color);
      colors.push(color);
    });

    return arr;
  };

  const segColors = weelColors();

  return (
    <div
    style={{
      display: "flex",
      justifyContent: "center",
      paddingTop: "480px",
      paddingBottom: "80px",
      background: `url(${IMAGES.background})`,
      backgroundSize: "cover",  // Προσαρμογή του φόντου στο μέγεθος του παραθύρου
  backgroundRepeat: "no-repeat",  // Απενεργοποίηση της επανάληψης της εικόνας
    backgroundPosition: "center",  // Τοποθέτηση της εικόνας στο κέντρο
    backgroundPosition: "top"  // Τοποθέτηση στο πάνω μέρος του χώρου
    }}
    >
      {show}
      <Wheel2Component
        segments={segments}
        segColors={segColors}
        winningSegment={"6"} 
        onFinished={(winner) => onFinished(winner, giftCounts)}
        primaryColor="gray"
        contrastColor="white"
        buttonText="Spin"
        isOnlyOnce={true}
      />
    </div>
  );
}

// Συνδέουμε το component με το Redux store
const mapStateToProps = (state) => {
  return {
    giftCounts: state.giftCounts, // Λαμβάνουμε το giftCounts από το state του Redux
  };
};

// Συνδέουμε το component με τις action creators για το Redux
const mapDispatchToProps = (dispatch) => {
  return {
    updateGiftCounts: (giftCounts) => {
      dispatch({ type: 'UPDATE_GIFT_COUNTS', payload: giftCounts });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wheel2); // Συνδέουμε το component με το Redux
