// giftCountsReducer3.js

const initialState = {}; // Αφαιρέστε το αρχικό κατάσταση από εδώ

  
  const giftCountsReducer3 = (state = initialState, action) => {
    switch (action.type) {
      case "UPDATE_GIFT_COUNTS_3":
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };
  
  export default giftCountsReducer3;