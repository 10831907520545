import React from 'react';
import { Link } from 'react-router-dom';
import './brelock.css';

function BrelockPage() {
  return (
    <div className="brelock-container">
    {/* Code to show only if we are on app.js */}
   
      <div id="brelock-button-container">
        <button className="brelockbutton">
          <Link to = "/data">Πίσω</Link>
        </button>
      </div>
  
  </div>
  );
}



export default BrelockPage;
