import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import WheelComponent from "./wheel1.component";
import "./wheel1.css";
import { useNavigate, useLocation } from 'react-router-dom';
import { firestore } from '../../services/firebase.service';
import IMAGES from "../../assets";

function Wheel1(props) {
  const [show, setShow] = useState(false);
  const { giftCounts2 } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');

  useEffect(() => {
    if (Object.keys(giftCounts2).length === 0) {
      const fetchGifts = async () => {
        try {
          const giftsRef = firestore.collection("gifts");
          const snapshot = await giftsRef.get();
          const giftData = {};
          snapshot.forEach((doc) => {
            const name = doc.id;
            const data = doc.data();
            giftData[name] = data.quantity;
          });

          props.updateGiftCounts2(giftData);

          console.log('Πήραμε αριθμούς από τη βάση:', giftData);

          setShow(true);
        } catch (error) {
          console.error("Σφάλμα κατά την ανάκτηση των δώρων από τη βάση δεδομένων:", error);
        }
      };

      fetchGifts();
    } else {
      setShow(true);
    }
  }, []);

  /*const checkLuckySeatDrawing = () => {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const hours = now.getHours();

    console.log("Έλεγχος για το Lucky Seat");
    console.log("Ημέρα της εβδομάδας:", dayOfWeek);
    console.log("Ώρα:", hours);

    if (dayOfWeek === 4 && ((hours >= 18.4 && hours < 18.5) || (hours >= 20 && hours < 20.5))) {
      if (giftCounts2 && giftCounts2["Lucky Seat"] > 0) {
        console.log("Υπάρχουν διαθέσιμα Lucky Seat");
        const updatedCounts = { ...giftCounts2 };
        updatedCounts["Lucky Seat"]--;
        
        props.updateGiftCounts2(updatedCounts);

        saveGiftToDatabase(userId, "Lucky Seat");

        navigate('/luckyseat');
      } else {
        console.log("Δεν υπάρχουν διαθέσιμα Lucky Seat");
        navigate('/sorry');
      }
    }
  };*/

  const giftToRouteMap = {
    
    
    "100 Free Spins": '/spins',
    "Clappers": '/regular',
    "€50 free bet": '/bet',
    "Jockey": '/jockey',
    "Aircash MasterCard": '/stressball',
    "Brelock": '/brelock',
    "Sorry": '/sorry',
    "Lucky Kick": '/luckyseat'
      };

  

  

  const onFinished = async (winner, giftCounts2) => {
    
    console.log("winner:", winner);
    if (giftCounts2[winner] > 0) {
      console.log('Κέρδισες:', winner);
      // Το δώρο υπάρχει στη βάση, πηγαίνουμε στη σελίδα του δώρου
      navigate(giftToRouteMap[winner]);
      saveGiftToDatabase(userId, winner);
  
      // Ενημερώνουμε την ποσότητα του δώρου στη βάση
      updateGiftQuantity(winner);

      const updatedCounts = { ...giftCounts2 };
      updatedCounts[winner]--;

      // redux for giftcounts
      props.updateGiftCounts2(updatedCounts);
  
    } else {
      // Το δώρο δεν υπάρχει στη βάση, βρίσκουμε το επόμενο δώρο στη λίστα των segments
      const nextGiftIndex = segments.indexOf(winner) + 1;
      const nextGift = segments[nextGiftIndex];
      
      if (giftCounts2[nextGift] > 0) {
        console.log('1o segment', nextGift);
        // Πηγαίνουμε στη σελίδα του επόμενου δώρου
        navigate(giftToRouteMap[nextGift]);
        saveGiftToDatabase(userId, nextGift);
        updateGiftQuantity(nextGift);
        const updatedCounts = { ...giftCounts2 };
      updatedCounts[nextGift]--;

      // redux for giftcounts
      props.updateGiftCounts2(updatedCounts);
      } else {
        const nextGiftIndex = segments.indexOf(winner) + 2;
      const nextGift = segments[nextGiftIndex];
      if (giftCounts2[nextGift] > 0) {
        console.log('2o segment', nextGift);
        // Πηγαίνουμε στη σελίδα του επόμενου δώρου
        navigate(giftToRouteMap[nextGift]);
        saveGiftToDatabase(userId, nextGift);
        updateGiftQuantity(nextGift);
        const updatedCounts = { ...giftCounts2 };
      updatedCounts[nextGift]--;

      // redux for giftcounts
      props.updateGiftCounts2(updatedCounts);
      }else{
        const nextGiftIndex = segments.indexOf(winner) + 3;
        const nextGift = segments[nextGiftIndex];
        if(giftCounts2[nextGift] > 0) {
          console.log('3o segmen',nextGift);
          navigate(giftToRouteMap[nextGift]);
          saveGiftToDatabase(nextGift);
          updateGiftQuantity(nextGift);
          const updatedCounts = { ...giftCounts2};
        updatedCounts[nextGift]--;
        
        //redux for giftCounts
        props.updateGiftCounts2(updatedCounts);
        }else{
        console.log('tipota');
        // Δεν υπάρχει επόμενο δώρο, πηγαίνουμε στη σελίδα 'Sorry'
        navigate('/stressball');
        saveGiftToDatabase(userId, "Aircash MasterCard");
      }
    }
    }
    }
  };
  
  
  const saveGiftToDatabase = (userId, gift) => {
    const userRef = firestore.collection("data").doc(userId);
    userRef
      .update({
        gift: gift,
      })
      .then(() => {
        console.log("Επιτυχής αποθήκευση του δώρου στη βάση δεδομένων.");
      })
      .catch((error) => {
        console.error("Σφάλμα κατά την αποθήκευση του δώρου:", error);
      });
  };

  const updateGiftQuantity = async (giftName) => {
    try {
      const giftsRef = firestore.collection("gifts");
      const giftDoc = await giftsRef.doc(giftName).get();

      if (giftDoc.exists) {
        let currentQuantity = giftDoc.data().quantity;
        if (currentQuantity > 0) {
          await giftsRef.doc(giftName).update({
            quantity: currentQuantity - 1,
          });
          console.log(`Επιτυχής ενημέρωση της ποσότητας για το δώρο "${giftName}".`);
        } else {
          console.warn(`Το δώρο "${giftName}" δεν είναι διαθέσιμο.`);
        }
      } else {
        console.warn(`Το δώρο "${giftName}" δεν βρέθηκε στη συλλογή "gifts2".`);
      }
    } catch (error) {
      console.error(`Σφάλμα κατά την ενημέρωση της ποσότητας για το δώρο "${giftName}":`, error);
    }
  };

  const segments = [
    "Aircash MasterCard",
    "€50 free bet",
    "Jockey",
    "100 Free Spins",
    "Clappers",
    "Brelock",
    "Sorry",
    "Lucky Kick"
    

    
  ];

  const weelColors = () => {
    let arr = [];
    let colors = ["#6DF1FF", "#05B7BA"];
    segments.forEach((el) => {
      let color = colors.shift();
      arr.push(color);
      colors.push(color);
    });

    return arr;
  };

  const segColors = weelColors();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "480px",
        paddingBottom: "80px",
        background: `url(${IMAGES.background})`,
        backgroundSize: "cover",  // Προσαρμογή του φόντου στο μέγεθος του παραθύρου
    backgroundRepeat: "no-repeat",  // Απενεργοποίηση της επανάληψης της εικόνας
      backgroundPosition: "center",  // Τοποθέτηση της εικόνας στο κέντρο
      backgroundPosition: "top"  // Τοποθέτηση στο πάνω μέρος του χώρου
      }}
    >
      
      {show}
      <WheelComponent
        segments={segments}
        segColors={segColors}
        winningSegment={"8"}
        onFinished={(winner) => onFinished(winner, giftCounts2)}
        primaryColor="gray"
        contrastColor="white"
        buttonText="Spin"
        isOnlyOnce={true}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    giftCounts2: state.giftCounts2,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGiftCounts2: (giftCounts2) => {
      dispatch({ type: 'UPDATE_GIFT_COUNTS_2', payload: giftCounts2 });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wheel1);
