import React from 'react';
import { Link } from 'react-router-dom';
import './stressball.css';

function StressballPage() {
  return (
    <div className="stressball-container">
    {/* Code to show only if we are on app.js */}
   
      
      <div id="stressball-button-container">
        <button className="stressballbutton">
          <Link to = "/data">Πίσω</Link>
        </button>
      </div>
  
  </div>
  );
}



export default StressballPage;
