import React, { useState, useEffect } from 'react';
import { firestore } from '../../services/firebase.service';

import './physicalwheel.css';

function PhysicalWheel() {
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    city: '',
    gift: ''
    
  });

  const [errors, setErrors] = useState({
    phone: '',
    email: '',
  });


  const [showPopup, setShowPopup] = useState(false);


  const citiesList = ["Θεσσαλονίκη"];

  const giftList = ["Antistress Ball", "Brelock", "Sorry", "Cap", "100 Free Spins", "€50 Free bet"];

  const [tabletId, setTabletId] = useState('');

  
  

  useEffect(() => {
    const storedTabletId = localStorage.getItem('tabletId');
    if (storedTabletId) {
      setTabletId(storedTabletId);
    } else {
      const newTabletId = generateTabletId();
      localStorage.setItem('tabletId', newTabletId);
      setTabletId(newTabletId);
    }
    
  });


  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const userRef = firestore.collection('physical').doc();
      const userId = userRef.id;

      userRef
        .set({
          ...formData,
          userId: userId,
          tabletId: tabletId, //  tabletId στα δεδομένα
          time: new Date().toLocaleString(),
        })
        .then(() => {
          console.log('Succesful insert in Firestore');
          setShowPopup(true);
        // Αδειάζουμε τα πεδία
        setFormData({
          name: '',
          surname: '',
          email: '',
          phone: '',
          city: '',
          gift: ''
        });

        setTimeout(() => {
            setShowPopup(false);
          }, 3000);

        })
        .catch((error) => {
          console.error('Σφάλμα κατά την αποθηκευση στο Firestore:', error);
        });
    } else {
      console.log('Συμπλήρωσε όλα τα υποχρεωτικά πεδία');
    }
  };

  const validateForm = () => {
    const { city, name, surname, phone, email, newsletter, gift } = formData;
    return city && name && surname && validatePhone(phone) && validateEmail(email) && gift ;
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  return (
    <div className="data-container">
      <div className="city-container">
        <select name="city" value={formData.city} onChange={handleChange} required>
          <option value="">Επιλέξτε πόλη</option>
          {citiesList.map((city) => (
            <option key={city} value={city}>
              {city}
            </option>
          ))}
        </select>
      </div>

      <div className="name-container">
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Όνομα:"
        />
      </div>

      <div className="surname-container">
        <input
          type="text"
          name="surname"
          value={formData.surname}
          onChange={handleChange}
          placeholder="Επίθετο:"
        />
      </div>

      <div className="phone-container">
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Τηλέφωνο:"
        />
        {errors.phone && <span className="error">{errors.phone}</span>}
      </div>

      <div className="email-container">
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email:"
        />
        {errors.enail && <span className="error">{errors.email}</span>}
      </div>

      <div className="gift-container">
        <select name="gift" value={formData.gift} onChange={handleChange} required>
          <option value="">Επιλέξτε δώρο</option>
          {giftList.map((gift) => (
            <option key={gift} value={gift}>
              {gift}
            </option>
          ))}
        </select>
      </div>

          

      <div className="submitbutton-container">
        <button className="submitbutton" onClick={handleSubmit} disabled={!validateForm()}>
          Υποβολή
        </button>
      </div>
      {showPopup && (
  <div className="popup">
    <p>Data Inserted</p>
  </div>
)}
    </div>
  );
}

function generateTabletId() {
  //τυχαιος τροπος για την δημιουργια του tabletid
  return 'tablet-' + Math.random().toString(36).substring(2, 15);
}

export default PhysicalWheel;
