//  σελίδα wheel5.js
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

function Wheel5() {
  const location = useLocation();
  const { userId } = useParams();

  return (
    <div>
      <h1>Wheel 5 Page</h1>
      <p>User ID: {userId}</p>
    </div>
  );
}

export default Wheel5;