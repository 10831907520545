import React, { useState } from 'react';

import { Route, Routes, Link, useLocation, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import Wheel1 from '../wheel1/wheel1';
import Data from '../data/data';
import Wheel2 from '../wheel2/wheel2';
import Wheel3 from '../wheel3/wheel3';
import Wheel4 from '../wheel4/wheel4';
import Wheel5 from '../wheel5/wheel5';
import WheelComponent from '../wheel1/wheel1.component';
import TrPortal from '../portal/portal';
import BetPage from '../bet/bet';
import JerseyPage from '../jersey/jersey';
import RegularPage from '../regular/regular';
import VIPPage from '../vip/vip';
import SpinsPage from '../spins/spins';
import SorryPage from '../sorry/sorry';
import LuckyseatPage from '../lucky-seat/luckyseat';
import JockeyPage from '../jockey/jockey';
import StressballPage from '../stressball/stressball';
import BrelockPage from '../brelock/brelock';
import DashboardHome from '../dashboardhome /dashboardhome';
import PhysicalWheel from '../physical-wheel/physicalwheel';
import Gkelakia from '../gkelakia/gkelakia';
import './App.css';

function App() {
  const [formData, setFormData] = React.useState({
    name: '',
    surname: '',
    email: '',
    phone: '',
    city: '',
    newsletter: false,
  });

  const handleChange = (e) => {
    const { name, value} = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () =>{
    console.log('Form data:', formData);
    //Κώδικας για αποθήκευση στο Firestore
  };



  return (
    <div>
      <Routes>
        <Route path="/data" element= {<Data formData={formData} handleChange={handleChange} handleSubmit={handleSubmit}/>} />
        <Route path="/wheel1" element={<Wheel1 />} />
        <Route path="/wheel2" element={<Wheel2 />} />
        <Route path="/wheel3" element={<Wheel3 />} />
        <Route path="/wheel4" element={<Wheel4 />} />
        <Route path="/wheel5" element={<Wheel5 />} />
        <Route path="/bet" element={<BetPage />} />
        <Route path="/spins" element={<SpinsPage />} />
        <Route path="/jersey" element={<JerseyPage />} />
        <Route path="/sorry" element={<SorryPage />} />
        <Route path="/vip" element={<VIPPage />} />
        <Route path="/regular" element={<RegularPage />} />
        <Route path="/luckyseat" element={<LuckyseatPage />} />
        <Route path="/jockey" element={<JockeyPage />} />
        <Route path="/stressball" element={<StressballPage />} />
        <Route path="/brelock" element={<BrelockPage />} />
        <Route path="/dashboardhome" element={<DashboardHome />} />
        <Route path="/physicalwheel" element={<PhysicalWheel />} />
        <Route path="/gkelakia" element={<Gkelakia />} />
        <Route path="/" element={<NavigationContainer />} />
      </Routes>
    </div>
  );
}

function NavigationContainer() {
  const location = useLocation();
  const showButton = !location.pathname.includes('/data');

  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [password, setPassword] = useState('');

  const handleDashboardClick = () => {
    // Open the modal
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    // Close the modal
    setIsModalOpen(false);
  };

  const handleModalSubmit = () => {
    // Check if the password is correct (for simplicity, using '1234')
    if (password === '1234') {
      // If correct, navigate to dashboardhome
      navigate('/dashboardhome');
      // Close the modal
      setIsModalOpen(false);
    } else {
      // If incorrect, you can display an error message or handle it as needed
      alert('Incorrect password. Please try again.');
    }
  };

  return (
    <div className="app-container">
      {/* Code to show only if we are on app.js */}
      {showButton && (
        <div id="next-button-container">
        <div>
          <button className="startbutton">
            <Link to="/data">Digital Δωροτροχός</Link>
          </button>
        </div>
        <div style={{ marginTop: '10px' }}>
          <button className="physicalbutton">
            <Link to="/physicalwheel">Επιδαπέδιος Δωροτροχός</Link>
          </button>
        </div>
        <div style={{ marginTop: '10px' }}>
          <button className="gkelakiabutton">
            <Link to="/gkelakia">Γκελάκια</Link>
          </button>
        </div>
        {/* Add a new button for dashboardhome */}
        <div style={{ marginTop: '10px' }}>
          <button className="dashboard-button" onClick={handleDashboardClick}>
            Digital Dashboard
          </button>
        </div>
      </div>
      
      )}
      <Routes>
        <Route path= "/data" element= {<Data />} />
      </Routes>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleModalClose}
        contentLabel="Enter Password"
      >
        <div>
          <h2>Enter Password</h2>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button onClick={handleModalSubmit}>Submit</button>
        </div>
      </Modal>

      
    </div>
  );
}

export default App;
