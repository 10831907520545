import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import Wheel3Component from "./wheel3.component";
import "./wheel3.component";
import { useNavigate, useLocation } from 'react-router-dom';
import { firestore } from '../../services/firebase.service';
import IMAGES from "../../assets";

function Wheel3(props) {
  const [show, setShow] = useState(false);
  const { giftCounts3 } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get('userId');

  useEffect(() => {
    if (Object.keys(giftCounts3).length === 0) {
      const fetchGifts = async () => {
        try {
          const giftsRef = firestore.collection("gifts3");
          const snapshot = await giftsRef.get();
          const giftData = {};
          snapshot.forEach((doc) => {
            const name = doc.id;
            const data = doc.data();
            giftData[name] = data.quantity;
          });

          props.updateGiftCounts3(giftData);

          console.log('Πήραμε αριθμούς από τη βάση:', giftData);

          setShow(true);
        } catch (error) {
          console.error("Σφάλμα κατά την ανάκτηση των δώρων από τη βάση δεδομένων:", error);
        }
      };

      fetchGifts();
    } else {
      setShow(true);
    }
  }, []);

  const checkLuckySeatDrawing = () => {
    const now = new Date();
    const dayOfWeek = now.getDay();
    const hours = now.getHours();

    console.log("Έλεγχος για το Lucky Seat");
    console.log("Ημέρα της εβδομάδας:", dayOfWeek);
    console.log("Ώρα:", hours);

    if (dayOfWeek === 4 && ((hours >= 18.4 && hours < 18.5) || (hours >= 20 && hours < 20.5))) {
      if (giftCounts3 && giftCounts3["Lucky Seat"] > 0) {
        console.log("Υπάρχουν διαθέσιμα Lucky Seat");
        const updatedCounts = { ...giftCounts3 };
        updatedCounts["Lucky Seat"]--;
        
        props.updateGiftCounts3(updatedCounts);

        saveGiftToDatabase(userId, "Lucky Seat");

        navigate('/luckyseat');
      } else {
        console.log("Δεν υπάρχουν διαθέσιμα Lucky Seat");
        navigate('/sorry');
      }
    }
  };

  const onFinished = (winner, giftCounts3) => {
    console.log('ante na doume', winner);
    console.log('dwrakia', giftCounts3);
    
    if (giftCounts3[winner] > 0) {
      const updatedCounts = { ...giftCounts3 };
      updatedCounts[winner]--;
      
      props.updateGiftCounts3(updatedCounts);
      setShow(winner);
      saveGiftToDatabase(userId, winner);
      updateGiftQuantity(winner);
  
      switch (winner) {
        case "VIP Tickets":
          navigate('/vip');
          break;
        case "Regular Tickets":
          navigate('/regular');
          break;
        case "T-Shirt":
          navigate('/jersey');
          break;
        case "100 Free Spins":
          navigate('/spins');
          break;
        case "€50 free bet":
          navigate('/bet');
          break;
        case "Sorry":
          navigate('/sorry');
          break;
        case "Lucky Seat":
          navigate('/luckyseat');
          break;
        case "Jockey":
          navigate('/jockey');
          break;
        default:
          break;
      }
    } else {
      saveGiftToDatabase(userId, "Sorry");
      setShow("Sorry...");
      navigate('/sorry');
      console.log('Δεν υπάρχουν διαθέσιμα δώρα', giftCounts3);
    }
  };
  
  const saveGiftToDatabase = (userId, gift) => {
    const userRef = firestore.collection("data").doc(userId);
    userRef
      .update({
        gift: gift,
      })
      .then(() => {
        console.log("Επιτυχής αποθήκευση του δώρου στη βάση δεδομένων.");
      })
      .catch((error) => {
        console.error("Σφάλμα κατά την αποθήκευση του δώρου:", error);
      });
  };

  const updateGiftQuantity = async (giftName) => {
    try {
      const giftsRef = firestore.collection("gifts3");
      const giftDoc = await giftsRef.doc(giftName).get();

      if (giftDoc.exists) {
        let currentQuantity = giftDoc.data().quantity;
        if (currentQuantity > 0) {
          await giftsRef.doc(giftName).update({
            quantity: currentQuantity - 1,
          });
          console.log(`Επιτυχής ενημέρωση της ποσότητας για το δώρο "${giftName}".`);
        } else {
          console.warn(`Το δώρο "${giftName}" δεν είναι διαθέσιμο.`);
        }
      } else {
        console.warn(`Το δώρο "${giftName}" δεν βρέθηκε στη συλλογή "gifts2".`);
      }
    } catch (error) {
      console.error(`Σφάλμα κατά την ενημέρωση της ποσότητας για το δώρο "${giftName}":`, error);
    }
  };

  const segments = [
    "VIP Tickets",
    "Regular Tickets",
    "T-Shirt",
    "100 Free Spins",
    "€50 free bet",
    "Sorry...",
    "Lucky Seat",
    "Jockey"
  ];

  const weelColors = () => {
    let arr = [];
    let colors = ["#6DF1FF", "#05B7BA"];
    segments.forEach((el) => {
      let color = colors.shift();
      arr.push(color);
      colors.push(color);
    });

    return arr;
  };

  const segColors = weelColors();

  return (
    <div
    style={{
      display: "flex",
      justifyContent: "center",
      paddingTop: "480px",
      paddingBottom: "80px",
      background: `url(${IMAGES.background})`,
      backgroundSize: "cover",  // Προσαρμογή του φόντου στο μέγεθος του παραθύρου
  backgroundRepeat: "no-repeat",  // Απενεργοποίηση της επανάληψης της εικόνας
    backgroundPosition: "center",  // Τοποθέτηση της εικόνας στο κέντρο
    backgroundPosition: "top"  // Τοποθέτηση στο πάνω μέρος του χώρου
    }}
    >
      {show}
      <Wheel3Component
        segments={segments}
        segColors={segColors}
        winningSegment={"8"}
        onFinished={(winner) => onFinished(winner, giftCounts3)}
        primaryColor="gray"
        contrastColor="white"
        buttonText="Spin"
        isOnlyOnce={true}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    giftCounts3: state.giftCounts3,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateGiftCounts3: (giftCounts3) => {
      dispatch({ type: 'UPDATE_GIFT_COUNTS_3', payload: giftCounts3 });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Wheel3);
