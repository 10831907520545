// giftCountsReducer.js

const initialState = {}; // Αφαιρέστε το αρχικό κατάσταση από εδώ

  
  const giftCountsReducer = (state = initialState, action) => {
    switch (action.type) {
      case "UPDATE_GIFT_COUNTS":
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };
  
  export default giftCountsReducer;
  